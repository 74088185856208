import { combineReducers } from '@reduxjs/toolkit';
// Import your reducers here
import userSlice from './slice/user/userSlice';
import userInfoSlice from './slice/userInfo/userInfoSlice';
import eventSlice from './slice/event/eventSlice';
import eventDataSlice from './slice/eventData/eventDataSlice';
import newsSlice from './slice/news/newsSlice';
import openBetSlice from './slice/openBet/openBetSlice';
import fancyDataSlice from './slice/fancyData/fancyDataSlice';
import dataSlice from './slice/betting/bettingSlice';


const rootReducer = combineReducers({
  user: userSlice,
  userInfo: userInfoSlice,
  events: eventSlice,
  eventData: eventDataSlice,
  fancyData: fancyDataSlice,
  news: newsSlice,
  bets: openBetSlice,
  data: dataSlice
});

export default rootReducer;
