// import './App.css';
import '../../App.css'
import { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import Appconfig from '../../config/config'
import { useSelector, useDispatch } from 'react-redux';

function News() {
    const location = useLocation();
    const navigate = useNavigate();
    // const [userNews, setUserNews] = useState([]);
    const userInfos = useSelector((state) => state.news); // Use selector to access state
    const userNews = userInfos?.news.length > 0 ? userInfos?.news : [];

    // function getNews() {

    //     var data = JSON.stringify({
    //         site_id: Appconfig.superAdmin,
    //     });

    //     var config = {
    //         method: "post",
    //         url: `${Appconfig.apiUrl}news/getNews`,
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: data,
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             // console.log('response.data', response.data);
    //             if (response.data.result) {
    //                 setUserNews(response.data.resultData)
    //             }
    //             else {
    //                 console.log(response)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    // useEffect(() => {
    //     getNews();
    // }, [])

    return (
        <>
            {
                userNews?.map(news => {
                    return (
                        news?.message
                    )
                })
            }
        </>
    );
}


export default News;
